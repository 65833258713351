import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import ICancion from "../components/Interfaces/ICancion";
import IGrupoCanciones from "../components/Interfaces/IGrupoCanciones";
import CelebracionesContext from "../contextManagment/celebraciones/celebracionesContext";
import parse from "html-react-parser";
import { close } from "ionicons/icons";
import { useHistory } from "react-router";
const BancoCancionesPage = () => {
  const {
    mostrarLoading,
    grupoCanciones,
    canciones,
    getGruposBd,
    getcancionesTodas,
  } = useContext(CelebracionesContext);

  const history = useHistory();
  const listRef = useRef<HTMLIonListElement>(null);

  const [llamoGrupos, setLlamoGrupos] = useState<boolean>(false);
  const [mostrarModalCancion, setMostrarModalCancion] =
    useState<boolean>(false);
  const ModalCancion = useRef<HTMLIonModalElement>(null);
  const [results, setResults] = useState([]);
  const [tituloCancionSeleccionada, setTituloCancionSeleccionada] =
    useState<string>("");
  const [letraCancion, setLetraCancion] = useState<string>("");
  const [valueSearch, setValueSearch] = useState("");
  useEffect(() => {
    if (!llamoGrupos) {
      getGruposBd();
      getcancionesTodas();
    }
  }, [llamoGrupos]);

  const buscarCancion = (ev: any) => {
    let query: string = "";
    const target = ev.target as HTMLIonSearchbarElement;

    if (target)
      query = target
        .value!.toLowerCase().trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    if (query === "") {
      setResults([]);
      setValueSearch("");
      return;
    }
    setValueSearch(target.value ? target.value : "");
    if (canciones) {
      setResults(
        canciones.filter((d: ICancion) => {
          //busqueda en minusculas y sin acentos
          return (
            (d.Nombre
              ? d.Nombre.toLocaleLowerCase().trim()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .indexOf(query) > -1
              : 0) ||
            (d.Cancion
              ? d.Cancion.toLocaleLowerCase().trim()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .indexOf(query) > -1
              : 0)
          );
        })
      );
    }
  };

  const mostrarCancionSeleccionada = (cancion: ICancion) => {
    history.push(`/page/CancionEscucha/${cancion.Id}`);
  };
  const getAlturaResultados = () => {
    if (!listRef?.current?.clientHeight) {
      return 125;
    }
    if (
      listRef?.current?.clientHeight >
      document.documentElement.offsetHeight / 1.8
    ) {
      return (
        document.documentElement.offsetHeight -
        document.documentElement.offsetHeight / 1.6
      );
    } else {
      return listRef?.current?.clientHeight;
    }
  };
  return (
    <IonPage>
      <IonLoading
        translucent={true}
        isOpen={mostrarLoading}
        message={"Espera un momento..."}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Banco Canciones</IonTitle>
        </IonToolbar>
        <IonSearchbar
          placeholder="Buscar canciones"
          showClearButton="focus"
          value={valueSearch}
          color="light"
          onIonBlur={() => {
            setTimeout(() => {
              setResults([]);
              setValueSearch("");
            }, 300);
          }}
          onIonInput={(ev) => buscarCancion(ev)}
        ></IonSearchbar>
        {results.length > 0 ? (
          <IonContent
            style={{
              height: getAlturaResultados(),
            }}
            scrollY={true}
            color="light"
          >
            <IonList
              style={{
                marginTop: "-0.4rem",
                backgroundColor: "#f5f6f9",
              }}
              inset={true}
              ref={listRef}
            >
              {results.map((ca: ICancion, indx) => (
                <IonItem
                  color="light"
                  key={indx}
                  onClick={() => mostrarCancionSeleccionada(ca)}
                >
                  <IonText>
                    <h5>{ca.Nombre}</h5>
                    <p style={{ fontSize: "smaller", marginTop: "-5px" }}>
                      {ca.Descripcion}
                    </p>
                  </IonText>
                </IonItem>
              ))}
            </IonList>
          </IonContent>
        ) : null}
      </IonHeader>
      <IonContent scrollEvents={false}>
        <IonRow style={{ position: "relative", zIndex: "3" }}>
          <IonCol size="12"></IonCol>
        </IonRow>
        <IonRow>
          {grupoCanciones.length > 0 ? (
            grupoCanciones.map((g: IGrupoCanciones) => (
              <IonCol key={g.Id}>
                <IonCard routerLink={`/page/ListCancionesGrupo/${g.Id}`}>
                  <img alt={g.NombreGrupo} src={g.ImgUrl} />
                  <IonCardHeader>
                    <IonCardTitle>{g.NombreGrupo}</IonCardTitle>
                    {/* <IonCardSubtitle>Card Subtitle</IonCardSubtitle> */}
                  </IonCardHeader>
                  {/* <IonCardContent>
                    Here's a small text description for the card content.
                    Nothing more, nothing less.
                  </IonCardContent> */}
                </IonCard>
              </IonCol>
            ))
          ) : (
            <IonCol>No se encontraron grupos</IonCol>
          )}
        </IonRow>

        <IonModal
          ref={ModalCancion}
          onWillDismiss={() => setMostrarModalCancion(false)}
          isOpen={mostrarModalCancion}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton
                  onClick={() => {
                    ModalCancion.current?.dismiss();
                  }}
                >
                  <IonIcon slot="end" icon={close} />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-center">
                {tituloCancionSeleccionada}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <pre
              className="preCancion"
              style={{
                fontSize: `1.2rem`,
                lineHeight: "normal",
                margin: "0.2rem -0.3rem 0rem -0.3rem ",
                paddingBottom: "15px",
              }}
            >
              {parse(letraCancion ? letraCancion : "")}
            </pre>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default BancoCancionesPage;
